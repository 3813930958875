import { api } from './MD5'

export const configFile = (obj) => {
  return api.get('/api/v1/common/config\n', obj)
}// 公共配置接口

export const login = (obj) => {
  return api.post('/api/v1/site/login\n', obj)
}// 登录

export const register = (obj) => {
  return api.post('/api/v1/site/register\n', obj)
}// 注册

export const logout = (obj) => {
  return api.post('/api/v1/site/logout\n', obj, localStorage.getItem('token'))
}// 退出登录

export const smsCode = (obj) => {
  return api.post('/api/v1/site/sms-code\n', obj)
}// 获取短信验证码

export const imgDetails = (obj) => {
  return api.get('/api/v1/common/img-details\n', obj)
}// 获取站内图片接口

export const uploadImage = (obj) => {
  return api.post('/api/v1/file/images\n', obj, localStorage.getItem('token'), true)
}// 公共上传图片接口

export const notifyList = (obj) => {
  return api.get('/api/v1/member/notify\n', obj, localStorage.getItem('token'))
}// 站内信列表

export const notifyDetail = (obj) => {
  return api.get('/api/v1/member/notify/detail\n', obj, localStorage.getItem('token'))
}// 站内信详情

// ----------------------- 用户接口 ----------------------------

export const getUserInfo = (obj) => {
  return api.get('/api/v1/member/member\n', obj, localStorage.getItem('token'))
}// 获取用户信息

export const updateMember = (obj) => {
  return api.post('/api/v1/member/member/update-member\n', obj, localStorage.getItem('token'))
}// 更新用户信息

export const realnameAudit = (obj) => {
  return api.post('/api/v1/member/realname-audit\n', obj, localStorage.getItem('token'))
}// 实名认证

export const sign = () => {
  return api.post('/api/v1/member/sign\n', '', localStorage.getItem('token'))
}// 用户签到

export const creditsLog = (obj) => {
  return api.get('/api/v1/member/credits-log\n', obj, localStorage.getItem('token'))
}// 账变列表接口

export const account = (obj) => {
  return api.post('/api/v1/member/account\n', obj, localStorage.getItem('token'))
}//  绑定USDT地址接口

export const cardUnbind = (obj) => {
  return api.post('/api/v1/member/card/unbind\n', obj, localStorage.getItem('token'))
}// 解绑USDT地址接口

export const updatePassword = (obj) => {
  return api.post('/api/v1/member/member/update-password\n', obj, localStorage.getItem('token'))
}// 修改登录密码

export const updatePayPassword = (obj) => {
  return api.post('api/v1/member/member/update-safety-password\n', obj, localStorage.getItem('token'))
}// 修改支付密码

export const buyVip = (obj) => {
  return api.post('/api/v1/member/vip-bill\n', obj, localStorage.getItem('token'))
}// 用户购买VIP

export const memberTeamList = (obj) => {
  return api.get('/api/v1/member/member/list\n', obj, localStorage.getItem('token'))
}// 用户下级列表

export const memberTeam = (obj) => {
  return api.get('/api/v1/member/member/team\n', obj, localStorage.getItem('token'))
}// 用户直属下级列表

export const bindTeam = (obj) => {
  return api.post('/api/v1/member/member/bind-team\n', obj, localStorage.getItem('token'))
}// 绑定直属上级

export const bindClient = (obj) => {
  return api.post('/api/v1/member/member/binding\n', obj, localStorage.getItem('token'))
}// 绑定ClientID

export const teamRelation = (obj) => {
  return api.get('/api/v1/member/member/relation\n', obj, localStorage.getItem('token'))
}// 关系树

export const upPwd = (obj) => {
  return api.post('/api/v1/site/up-pwd\n', obj)
}// 忘记密码

export const newMemberTeamList = (obj) => {
  return api.get('api/v1/member/member/list-new\n', obj, localStorage.getItem('token'))
}// 新的团队数据接口

export const information = (obj) => {
  return api.get('api/v1/member/member/information\n', obj, localStorage.getItem('token'))
}// 获取弹窗消息

export const teamDetail = (obj) => {
  return api.get('api/v1/member/member/team-detail\n', obj, localStorage.getItem('token'))
}// 获取团队信息

export const teamList = (obj) => {
  return api.get('api/v1/member/member/team-list\n', obj, localStorage.getItem('token'))
}// 获取团队列表

export const memberDetail = (obj) => {
  return api.get('api/v1/member/member/detail\n', obj, localStorage.getItem('token'))
}// 获取用户详情

// ----------------------- 保险接口 ----------------------------

export const insureList = (obj) => {
  return api.get('/api/v1/tea/insure-list\n', obj, localStorage.getItem('token'))
}// 保险列表

export const buyInsure = (obj) => {
  return api.post('/api/v1/tea/insure-bill\n', obj, localStorage.getItem('token'))
}// 创建保险订单

export const insureBillList = (obj) => {
  return api.get('/api/v1/tea/insure-bill', obj, localStorage.getItem('token'))
}// 保险订单列表

// ----------------------- 运单接口 ----------------------------

export const refreshTask = (obj) => {
  return api.get('/api/v1/task/order-task/clear-cache\n', obj, localStorage.getItem('token'))
}// 运单列表刷新

export const orderTask = (obj) => {
  return api.get('/api/v1/task/order-task\n', obj, localStorage.getItem('token'))
}// 运单列表

export const orderTaskSetting = (obj) => {
  return api.get('/api/v1/task/order-task-settings\n', obj)
}// 运单配置接口

export const buyOrderTask = (obj) => {
  return api.post('/api/v1/task/order-task\n', obj, localStorage.getItem('token'))
}// 运单下单

export const orderTaskBuy = (obj) => {
  return api.post('/api/v1/task/order-task/buy\n', obj, localStorage.getItem('token'))
}// 购买商品

export const orderTaskList = (obj) => {
  return api.get('/api/v1/task/order-task-list\n', obj, localStorage.getItem('token'))
}// 运单订单列表

export const getOrderTaskList = (obj) => {
  return api.get('/api/v1/task/order-task-list/get-task-name\n', obj, localStorage.getItem('token'))
}// 获取用户已经下单的任务名称

export const finishOrderTask = (obj) => {
  return api.post('/api/v1/task/order-task-list/get-profit\n', obj, localStorage.getItem('token'))
}// 根据小号名称提交结算运单收益

export const payDeliverFee = (obj) => {
  return api.post('/api/v1/task/order-task-list/pay-deliver-fee\n', obj, localStorage.getItem('token'))
}// 支付运费

export const getOneProfit = (obj) => {
  return api.post('/api/v1/task/order-task-list/get-one-profit\n', obj, localStorage.getItem('token'))
}// 申请提取某个订单的收益

export const getVerifyCode = (obj) => {
  return api.get('/api/v1/task/order-task/get-verify-code\n', obj, localStorage.getItem('token'))
}// 获取运单验证码

export const toVerifyCode = (obj) => {
  return api.post('/api/v1/task/order-task/verify\n', obj, localStorage.getItem('token'))
}// 验证验证码

export const getGoods = (obj) => {
  return api.get('/api/v1/task/order-task/get-goods\n', obj, localStorage.getItem('token'))
}// 获取商品id

export const getDeliverNum = (obj) => {
  return api.get('/api/v1/task/order-task-list/get-deliver-num\n', obj, localStorage.getItem('token'))
}// 获取运单订单号

export const getRefund = (obj) => {
  return api.post('/api/v1/task/order-task-list/re-found\n', obj, localStorage.getItem('token'))
}// 运单批量退款

export const getReturnGoods = (obj) => {
  return api.post('/api/v1/task/order-task-list/return-goods\n', obj, localStorage.getItem('token'))
}// 运单批量退货

export const getSummary = (obj) => {
  return api.get('/api/v1/task/order-task-list/summary\n', obj, localStorage.getItem('token'))
}// 结算明细

export const getRefundOne = (obj) => {
  return api.post('/api/v1/task/order-task-list/refund\n', obj, localStorage.getItem('token'))
}// 申请退款

export const getBacthRefund = (obj) => {
  return api.post('/api/v1/task/order-task-list/bacth-refund\n', obj, localStorage.getItem('token'))
}// 批量申请退款2

export const compensate = (obj) => {
  return api.post('/api/v1/task/order-task-list/compensate\n', obj, localStorage.getItem('token'))
}// 申请赔付

export const bacthCompensate = (obj) => {
  return api.post('/api/v1/task/order-task-list/bacth-compensate\n', obj, localStorage.getItem('token'))
}// 批量申请赔付

// ----------------------- 理财接口 ----------------------------

export const investmentProject = (obj) => {
  return api.get('/api/v1/tea/investment-project\n', obj, localStorage.getItem('token'))
}//  首页项目列表接口

export const productList = (obj) => {
  return api.get('/api/v1/tea/investment-project/list\n', obj, localStorage.getItem('token'))
}//  理财列表接口

export const projectDetail = (obj) => {
  return api.get('/api/v1/tea/investment-project/detail\n', obj, localStorage.getItem('token'))
}//  项目详情接口

export const projectBill = (obj) => {
  return api.post('/api/v1/tea/investment-bill\n', obj, localStorage.getItem('token'))
}//  申购项目接口

export const yuebaoOperation = (obj) => {
  return api.post('/api/v1/member/yuebao\n', obj, localStorage.getItem('token'))
}// 理财宝转入转出

export const yuebaoList = (obj) => {
  return api.get('/api/v1/member/yuebao\n', obj, localStorage.getItem('token'))
}// 理财宝转账列表

export const projectList = (obj) => {
  return api.get('/api/v1/tea/investment-bill\n', obj, localStorage.getItem('token'))
}//  已申购理财列表接口

// ----------------------- 充值提现接口 ----------------------------

export const rechargeList = (obj) => {
  return api.get('/api/v1/member/recharge-bill/list\n', obj, localStorage.getItem('token'))
}// 充值列表配置

export const addPay = (obj) => {
  return api.post('/api/v1/pay\n', obj, localStorage.getItem('token'))
}//  添加充值订单接口

export const rechargeBill = (obj) => {
  return api.get('/api/v1/member/recharge-bill\n', obj, localStorage.getItem('token'))
}//  充值订单列表接口

export const withdrawBill = (obj) => {
  return api.post('/api/v1/member/withdraw-bill\n', obj, localStorage.getItem('token'))
}//  申请提现接口

export const getWithdrawBill = (obj) => {
  return api.get('/api/v1/member/withdraw-bill\n', obj, localStorage.getItem('token'))
}//  提现订单列表接口

// ----------------------- 签到商品接口 ----------------------------

export const signGoodsList = (obj) => {
  return api.get('/api/v1/tea/sign-goods-list\n', obj)
}//  签到商品列表

export const signGoodsListDetail = (obj) => {
  return api.get('/api/v1/tea/sign-goods-list/detail\n', obj)
}//  签到商品详情

export const signGoodsBill = (obj) => {
  return api.post('/api/v1/tea/sign-goods-bill\n', obj, localStorage.getItem('token'))
}//  签到兑换商品

export const orderList = (obj) => {
  return api.get('/api/v1/tea/sign-goods-bill\n', obj, localStorage.getItem('token'))
}//  订单列表
export const APIopinipon = (obj) => {
  return api.post('/api/v1/common/opinion-list', obj, localStorage.getItem('token'))
}//  订单列表
